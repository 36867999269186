import React from 'react';
import musictech from '../assets/images/musictech.jpg';

const MusicTech = () => {
  return (
    <img
      src={musictech}
      alt="music-tech"
      style={{ width: '100vw', height: '600px' }}
    />
  );
};

export default MusicTech;
